import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, Subject, catchError, map, of, tap } from 'rxjs';
import { environment } from 'src/environment/environment';
import { Alert } from './components/alert/alert.interface';
import {
  BaseAuthResponse,
  SignInData,
  SignInResponse,
  SignUpResponse,
  SignupData,
  TokenRefreshResponse,
} from './defs/datatypes';
import { AnalyticsService } from './analytics.service';

const LSK_ID_TOKEN = 'idToken';
const LSK_REFRESH_TOKEN = 'refreshToken';
const LSK_ID_TOKEN_EXPIRATION = 'idTokenExpiration';
const LSK_SIGNUP_DATA = 'signupData';

@Injectable({ providedIn: 'root' })
export class AppService {
  private redirectUrl!: string;
  private displaySpinnerSubj = new Subject<boolean>();

  public displaySpinner$ = this.displaySpinnerSubj.asObservable();
  public returnSecureToken = false;
  public returnRefreshToken = false;
  public appId!: string;
  public idToken: string | null = null;
  public guestIdToken: string | null = null;
  public customToken: string | null = null;
  public email!: string;
  public profileName!: string;
  public initialLoginTab!: string;
  public userId: string | null = null;
  public refreshToken: string | null = null;
  public forceLogout: boolean = false;

  constructor(
    private readonly _http: HttpClient,
    private readonly _domSanitizer: DomSanitizer,
    private analyticsService: AnalyticsService
  ) {}

  public init() {
    this.refreshToken = localStorage.getItem(LSK_REFRESH_TOKEN) || null;

    if (this.refreshToken) {
      const url = this.getUrl('user-refreshTokens');
      let body = {
        refreshToken: this.refreshToken
      }

      return this._http
        .post<TokenRefreshResponse>(url, body)
        .pipe(
          map((response) => {
            this.saveUserData(response);

            return true;
          }),
          catchError(() => {
            this.logout();
            return of(true);
          })
        );
    }


    /* not all other stuff ready for it:
      - no logout feature
      - ?

    this.refreshToken = localStorage.getItem(LSK_REFRESH_TOKEN) || null;

    if (this.refreshToken) {
      const url = this.getUrl('user-refreshTokens');

      return this._http.post<TokenRefreshResponse>(url, { refreshToken: this.refreshToken }).pipe(
        map((response) => {
          this.saveUserData(response);

          return true;
        }),
        catchError(() => {
          this.idToken = null;
          this.refreshToken = null;
          this.userId = null;
          localStorage.removeItem(LSK_REFRESH_TOKEN);

          return of(true);
        })
      );
    }
    */

    return of(true);
  }

  public logout() {
    this.idToken = null;
    this.guestIdToken = null;
    this.refreshToken = null;
    this.userId = null;
    this.analyticsService.clearUserId();
    this.analyticsService.sendEvent('logout_success');
    localStorage.removeItem(LSK_REFRESH_TOKEN);
  }

  public isLogged() {
    if (this.idToken) {
      let decodedToken = JSON.parse(atob(this.idToken.split('.')[1]));
      if (decodedToken['email']) {
        return true;
      }
    }
    return false;
  }

  public saveUserData(data: SignInResponse) {
    this.idToken = data.idToken;
    this.refreshToken = data.refreshToken;
    this.userId = data.userId;
    this.analyticsService.setUserId(this.userId);
    this.customToken = data.customToken;
    localStorage.setItem(LSK_REFRESH_TOKEN, data.refreshToken);
  }

  public checkSessionForAccountMerge() {
    if (this.refreshToken) {
      const url = this.getUrl('user-refreshTokens');
      let body:any = {
        'refreshToken': this.refreshToken
      }

      if (this.guestIdToken) {
        body['idToken'] = this.guestIdToken;
      }

      return this._http
        .post<TokenRefreshResponse>(url, body)
        .pipe(
          map((response) => {
            this.saveUserData(response);

            return true;
          }),
          catchError(() => {
            this.logout();
            return of(true);
          })
        );
    }
    return of(true);
  }

  private getUrl(fnName: string): string {
    const base = environment.url.gen1.base;

    return `${base}/${fnName}`;
  }

  private getUrlChainOps(fName: string): string {
    const prefix = environment.url.chainOps.prefix;
    const sufix = environment.url.chainOps.suffix;
    const project = environment.url.chainOps.project;

    return `${prefix}${fName}-${project}-${sufix}`;
  }

  private getUrlGen2(fName: string): string {
    const prefix = environment.url.gen2.prefix;
    const sufix = environment.url.gen2.suffix;
    const project = environment.url.gen2.project;

    return `${prefix}${fName}-${project}-${sufix}`;
  }

  private withSpinner<T = Observable<any>>(request: Observable<unknown>) {
    this.showSpinner(true);

    return request.pipe(
      tap(() => this.showSpinner(false)),
      catchError((err) => {
        this.showSpinner(false);

        throw err;
      })
    ) as T;
  }

  public setUrlRedirect(redirect: string): void {
    if (!this.redirectUrl && redirect) {
      this.redirectUrl = redirect;
    }
  }

  public getUrlRedirect(token: string) {
    let url = `${this.redirectUrl}://?token=${token}`;
    return this._domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public sanitizeRedirectUrl(redirectrUrl: SafeResourceUrl) {
    return this._domSanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      redirectrUrl
    );
  }

  public getProjectInformation(appId: string): Observable<any> {
    const url = this.getUrl('projectsCollection-getProjectInfo');

    if (appId) {
      return this._http.post<any>(url, { appId });
    }

    return of();
  }

  public checkIfUserSatisfyProjectChainRequirements(appId: string) {
    const url = this.getUrl('wallets-isUserHasBlockchainRequirement');
    if (appId) {
      return this.withSpinner(this._http.post<any>(url, { appId }));
    }

    return of();
  }

  public createWallets(password: string) {
    const url = this.getUrlChainOps('create-wallets');
    if (password) {
      let body: any = { password: password };
      if (this.appId) {
        body['appId'] = this.appId;
      }
      return this.withSpinner(this._http.post<any>(url, body));
    }

    return of();
  }

  public signInWithEmailPassword(data: SignInData) {
    const signIn = this.getUrl('user-signInWithEmailPassword');
    if (this.appId) {
      data.appId = this.appId;
    }

    if (this.returnSecureToken) {
      data.returnSecureToken = this.returnSecureToken;
    }

    if (this.idToken) {
      let decodedToken = JSON.parse(atob(this.idToken.split('.')[1]));
      if (!decodedToken['email']) {
        data.idToken = this.idToken;
      }
    }

    return this.withSpinner<Observable<SignInResponse>>(
      this._http.post<any>(signIn, data)
    ).pipe(
      tap((response) => {
        this.saveUserData(response);
        console.log({ response });
      })
    );
  }

  public signUpWithEmailPassword(data: any) {
    const signupUrl = this.getUrl('user-signUpWithEmailPassword');

    let body: any = {
      email: data.email,
      password: data.password,
      displayName: data.full_name,
    };

    if (this.appId) {
      body['appId'] = this.appId;
    }

    if (this.returnSecureToken) {
      body['returnSecureToken'] = this.returnSecureToken;
    }

    if (this.idToken) {
      let decodedToken = JSON.parse(atob(this.idToken.split('.')[1]));
      if (!decodedToken['email']) {
        body['idToken'] = this.idToken;
      }
    }

    return this.withSpinner<Observable<SignUpResponse>>(
      this._http.post(signupUrl, body)
    ).pipe(
      tap((response) => {
        this.saveUserData(response);
        this.analyticsService.sendEvent('wallet_creation_api_call_initiated');
        this.createWallets(data.password)
          .pipe(
            catchError((error: any): Observable<any> => {
              console.log('Error on getProjectInformation: ', error);
              this.analyticsService.sendEvent(
                'wallet_creation_api_call_failure',
                error
              );
              return of();
            })
          )
          .subscribe((data) => {
            this.analyticsService.sendEvent(
              'wallet_creation_api_call_success',
              data
            );
          });
      })
    );
  }

  public recoverPassword(email: string) {
    const recoverUrl = this.getUrl('user-resetAccountPassword');
    let body: any = { email: email };

    if (this.appId) {
      body['appId'] = this.appId;
    }

    if (this.returnSecureToken) {
      body['returnSecureToken'] = this.returnSecureToken;
    }

    return this.withSpinner(this._http.post<any>(recoverUrl, body));
  }

  public sendVerificationEmail() {
    const sendUrl = this.getUrl('user-sendVerificationEmail');
    let body: any = { idToken: this.idToken };

    if (this.appId) {
      body['appId'] = this.appId;
    }

    if (this.returnSecureToken) {
      body['returnSecureToken'] = this.returnSecureToken;
    }

    return this.withSpinner(this._http.post<any>(sendUrl, body));
  }

  public mapError(error: 'INVALID_EMAIL' | 'EMAIL_NOT_FOUND'): Alert {
    const errorMap: { [key: string]: Alert } = {
      INVALID_EMAIL: {
        message: 'E-mail not found',
        type: 'warning',
      },
      EMAIL_NOT_FOUND: {
        message: 'E-mail not found',
        type: 'warning',
      },
      INVALID_PASSWORD: {
        message: "Password doesn't match",
        type: 'warning',
      },
      INVALID_ID_TOKEN: {
        message: 'Token is invalid, try the signin process again',
        type: 'error',
      },
      EMAIL_EXISTS: {
        message: 'This e-mail already exists',
        type: 'error',
      },
      TOO_MANY_ATTEMPTS_TRY_LATER: {
        message: "You've tried too many times, try again later",
        type: 'error',
      },
    };

    return errorMap[error];
  }

  showSpinner(state: boolean) {
    this.displaySpinnerSubj.next(state);
  }

  public checkEmailVerification() {
    const url = this.getUrl('user-isEmailVerified');
    let headers = new HttpHeaders();

    if (this.appId) {
      headers = headers.set('app-id', this.appId);
    }

    let body = {
      refreshToken: this.refreshToken,
    };

    return this._http.post<any>(url, body, { headers });
  }

  public checkIdToken(token: string) {
    if (this.idToken == null) {
      this.idToken = token;
    }
    let decodedToken = JSON.parse(atob(token.split('.')[1]));
    if (!decodedToken['email']) {
      this.guestIdToken = token;
      this.checkSessionForAccountMerge().subscribe();
    }
    /* TODO check if user real (account exists) / guest (no account)
    this.showSpinner(true);

    this._http.post(, {
      {
        userId: ,
        version: 2,
      }
    })
    */
  }

  encodeSignupData(data: SignupData) {
    return btoa(JSON.stringify(data));
  }

  decoreSignupData(data: string): SignupData | null {
    try {
      return JSON.parse(atob(data));
    } catch (err) {
      return null;
    }
  }
}
