import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Dialog } from '@angular/cdk/dialog';
import { AppService } from 'src/app/app.service';
import { Alert } from 'src/app/components/alert/alert.interface';

enum Tabs {
  Login,
  Register,
}

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
})
export class AuthComponent {
  Tabs = Tabs;
  activeTab = Tabs.Register;
  alert: Alert | null = null;

  get token() {
    return this.appService.idToken;
  }

  constructor(
    private readonly appService: AppService,
    private readonly router: Router,
    public dialog: Dialog
  ) {
    if (this.appService.forceLogout) {
      this.appService.logout();
    }

    if (this.appService.isLogged()) {
      this.router.navigate(['/check-requirements']);
    } else {
      if (this.appService.initialLoginTab === 'signin') {
        this.activeTab = Tabs.Login;
      }
      if (this.appService.initialLoginTab === 'signup') {
        this.activeTab = Tabs.Register;
      }
      if (this.appService.initialLoginTab === 'signin') {
        this.activeTab = Tabs.Login;
      }
    }
  }

  handleAlert(alert: Alert | null) {
    this.alert = alert;
  }

  switchToTab(tab: Tabs) {
    if (this.activeTab === tab) return;

    this.alert = null;
    this.activeTab = tab;
  }
}
