<div class="modal">
  <div class="modal__info success">
    <div class="modal__info-column">
      <div class="modal__info-logo">
        <img src="/assets/images/logo.png" alt="PLAY logo" />
      </div>

      <div class="modal__info-title">
        {{ "auth.accountCreated" | translate }}
      </div>
    </div>

    <div class="modal__info-text-container">
      <div class="modal__info-text">
        {{ "auth.needConfirmEmail" | translate }}
      </div>

      <button
        type="submit"
        [disabled]="process.onProgress"
        (click)="resendEmail()"
      >
        {{ "auth.resendVerification" | translate }}
      </button>
    </div>
  </div>
</div>

<app-alert
  *ngIf="alert"
  [type]="alert.type"
  [message]="alert.message"
></app-alert>
