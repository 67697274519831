import { Component, OnInit, inject } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { catchError, Observable, of } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Alert } from 'src/app/components/alert/alert.interface';
import { AnalyticsService } from '../../analytics.service';

@Component({
  templateUrl: 'check-requirements.component.html'
})

export class CheckRequirementsComponent implements OnInit {
  public alert!: Alert;
  public gameRequirementStatus = 'waiting_for_response';
  public resultText: string | null = null;
  public url: SafeUrl = '';

  constructor(
    private readonly appService: AppService,
    private analyticsService: AnalyticsService
  ) { }

  get token() {
    return this.appService.idToken;
  }

  ngOnInit(): void {
    if (!this.appService.returnSecureToken) {
      console.log(this.appService.customToken);
      console.log(this.appService.idToken);
      this.url = this.appService.getUrlRedirect(this.appService.customToken || '');
    } else {
      console.log(this.appService.idToken);
      console.log(this.appService.userId);
      console.log(this.appService.refreshToken);

      this.url = this.appService.getUrlRedirect(
        this.appService.returnRefreshToken
          ? this.appService.refreshToken || ''
          : this.appService.idToken || ''
      );
    }
    this.checkSatisfyProjectChainRequirements();
  }

  checkSatisfyProjectChainRequirements() {
    this.analyticsService.sendEvent('check_satisfy_requirements_initiated');
    this.appService.checkIfUserSatisfyProjectChainRequirements(this.appService.appId)
      .pipe(catchError((error: any): Observable<any> => {
        console.log('Error on getProjectInformation: ', error);
        this.alert = this.appService.mapError(error.error.message);
        return of();
      }))
      .subscribe((data) => {

        if (data?.hasBlockchainRequirement == true) {
          this.analyticsService.sendEvent('satisfy_project_requirements');
          this.gameRequirementStatus = "meet_requirements";
          this.shareProfile(true);
        }

        if (data?.hasBlockchainRequirement == false) {
          this.analyticsService.sendEvent('do_not_satisfy_project_requirements');
          this.gameRequirementStatus = "need_wallet_creation";
          this.shareProfile(false);
        }
      });
  }

  public shareProfile(closeModal = false) {
    if (this.token) {
      const session = {
        'userId': this.appService.userId,
        'idToken': this.appService.idToken,
        'refreshToken': this.appService.refreshToken,
        'closeModal': closeModal
      }

      console.log('share session', session);

      window.opener?.postMessage(
        { session },
        this.appService.sanitizeRedirectUrl(this.url)
      );
    }
  }
}
