import { UntypedFormGroup } from '@angular/forms';

export function showFieldErrs(form: UntypedFormGroup, fieldName: string): boolean {
  const field = form.get(fieldName);
  return field?.invalid && field?.errors && (field?.dirty || field?.touched) || false;
}

export function fieldHasErr(form: UntypedFormGroup, fieldName: string, errKey: string) {
  return form.get(fieldName)?.hasError(errKey);
}

export function showFormErrs(form: UntypedFormGroup) {
  return form.invalid && form.errors && (form.dirty || form.touched);
}

export function scrollToFirstInvalidField() {
  const tag = document.querySelector('form .ng-invalid');

  if (tag) {
    tag.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
