import { Component, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { Alert } from 'src/app/components/alert/alert.interface';
import { AppService } from 'src/app/app.service';
import { SignInData } from 'src/app/defs/datatypes';
import { fieldHasErr, scrollToFirstInvalidField, showFieldErrs } from 'src/app/utils/forms';
import { AnalyticsService } from '../../../analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {
  @Output() alertEmitter = new EventEmitter<Alert | null>(); 
  showFieldErrs = showFieldErrs;
  fieldHasErr = fieldHasErr;
  passwordVisible = false;

  signInForm = new FormGroup({
    email: new FormControl<string>('', [Validators.required]),
    password: new FormControl<string>('', [Validators.required]),
    rememberMe: new FormControl<boolean>(false)
  });

  constructor(
    private readonly appService: AppService,
    private readonly router: Router,
    private analyticsService: AnalyticsService
  ) {
    if (this.appService.email) {
      this.signInForm.patchValue({ email: this.appService.email });
    }
  }

  signIn(): void {
    this.signInForm.markAllAsTouched();
    if (this.signInForm.invalid) {
      scrollToFirstInvalidField();
      return;
    }

    this.alertEmitter.emit(null);
    this.signInWithEmailPassword();
  }

  signInWithEmailPassword(): void {
    const formData = this.signInForm.getRawValue() as SignInData;

    this.signInForm.disable();
    this.analyticsService.sendEvent("sign_in_attempt");

    this.appService
      .signInWithEmailPassword(formData)
      .pipe(catchError((error: any): Observable<any> => {
        console.log('Error on signin: ', error);
        this.analyticsService.sendEvent("sign_in_failure");
        if (error.error.message === 'EMAIL_NOT_VERIFIED') {
          this.appService.idToken = error.error.idToken;
          this.router.navigate(['/verify']);
        }

        this.alertEmitter.emit(this.appService.mapError(error.error.message));

        return of();
      }))
      .subscribe((data) => {
        this.analyticsService.sendEvent("sign_in_success");
        if (data.message === 'EMAIL_NOT_VERIFIED') {
          this.router.navigate(['/verify']);
          return;
        }

        this.router.navigate(['/check-requirements']);
      }).add(() => {
        this.signInForm.enable();
      });
  }
}
