import {
  HttpRequest, HttpInterceptorFn, HttpHandlerFn, HttpEvent,
} from '@angular/common/http';
import { inject } from '@angular/core';

import { Observable } from 'rxjs';
import { AppService } from '../app.service';

export const authInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const appService = inject(AppService);

  if (!request.url.includes('/assets/i18n')) { // looks like api request
    let headers = request.headers;

    if (appService.idToken) {
      headers = headers.set('Authorization', `Bearer ${appService.idToken}`);
    }

    return next(request.clone({ headers }));
  } else {
    return next(request);
  }
};
