<div class="modal">
  <div class="modal__login">
    <div class="modal__login-form form">
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="sendResetLink()">
        <div class="form__item">
          <div class="form__field">
            <i class="icon-email form__item-icon"></i>
            <input type="email" placeholder="{{ 'forms.emailAddress' | translate }}" formControlName="email"
              autocomplete="username">
          </div>

          <div class="form__field-error">
            <span *ngIf="emailControl?.errors?.['serverError']">
              {{ 'forms.'+emailControl?.errors?.['serverError'] | translate }}
            </span>
            <ng-container *ngIf="showErrors">
              <span *ngIf="emailControl?.errors?.['required']">
                {{ 'forms.emailRequired' | translate }}
              </span>
              <span *ngIf="emailControl?.errors?.['email']">
                {{ 'forms.validEmailRequired' | translate }}
              </span>
            </ng-container>
          </div>
        </div>

        <div class="form__item form__item-submit">
          <button type="submit" [disabled]="forgotPasswordForm.invalid || submitting">
            {{ 'forms.sendResetLink' | translate }}
            <i class="icon-arrow-right"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!--
<form [formGroup]="forgotPasswordForm">
  <div class="w-full mb-6">
    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="email">
      {{ 'forms.emailAddress' | translate }}
    </label>
    <input
      id="email"
      formControlName="email"
      class="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg p-3 leading-tight focus:outline-none"
      type="email"
      placeholder="{{ 'forms.emailAddress' | translate }}"
    />

    <span class="w-full text-red-600 font-bold" *ngIf="forgotPasswordForm.controls['email'].touched">
      <small *ngIf="forgotPasswordForm.controls['email'].hasError('required')">
        {{ 'forms.emailRequired' | translate }}
      </small>
      <small *ngIf="forgotPasswordForm.controls['email'].hasError('email')">
        {{ 'forms.validEmailRequired' | translate }}
      </small>
    </span>
  </div>

  <div class="w-full">
    <button [disabled]="forgotPasswordForm.invalid" class="button" (click)="sendResetLink()">
      <img *ngIf="forgotPasswordForm.disabled" src="/assets/loader.svg" />
      <span *ngIf="!forgotPasswordForm.disabled">
        {{ 'forms.sendResetLink' | translate }}
      </span>
    </button>
  </div>
</form>
-->