<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->

<div class="modal">
  <div class="modal__info">
    <div class="modal__info-column">
      <div class="modal__info-logo">
        <img src="/assets/images/logo.png" alt="PLAY logo" />
      </div>

      <div
        class="modal__info-title"
        *ngIf="
          gameRequirementStatus === 'waiting_for_response' ||
          gameRequirementStatus === 'need_wallet_creation'
        "
      >
        {{ "auth.signinOk" | translate }}
      </div>
      <div
        class="modal__info-title"
        *ngIf="gameRequirementStatus === 'meet_requirements'"
      >
        {{ "auth.success" | translate }}
      </div>
    </div>

    <div class="modal__info-column">
      <div
        class="modal__info-text"
        *ngIf="gameRequirementStatus === 'meet_requirements'"
      >
        {{ "forms.profileMeetsRequirements" | translate }}
      </div>

      <div class="modal__info-text-container">
        <ng-container *ngIf="gameRequirementStatus === 'need_wallet_creation'">
          <div class="modal__info-text">
            {{ "forms.accountRequireWallet" | translate }}
          </div>

          <a
            routerLink="/wallet-creation"
            class="button button__filled button__xs"
          >
            {{ "forms.continue" | translate }}
          </a>
        </ng-container>

        <div
          class="modal__info-text"
          *ngIf="gameRequirementStatus === 'waiting_for_response'"
        >
          {{ "forms.checkingProfile" | translate }}
        </div>

        <div class="modal__info-text">
          <a
            *ngIf="gameRequirementStatus === 'meet_requirements'"
            [href]="url"
            class="button button__filled button__xs"
          >
            {{ "forms.returnToGame" | translate }}
          </a>
          <a
            *ngIf="
              gameRequirementStatus === 'need_wallet_creation' ||
              gameRequirementStatus === 'waiting_for_response'
            "
            [href]="url"
            class="link"
          >
            <span>{{ "forms.skipAndReturnToGame" | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!--
<div class="share-profile" *ngIf="token != null">
  <button class="button" (click)="shareProfile()">
    <span>{{ 'forms.shareProfile' | translate }}</span>
  </button>
</div>
-->

  <app-alert
    class="auth-alert"
    *ngIf="alert"
    [type]="alert.type"
    [message]="alert.message"
  ></app-alert>
</div>
