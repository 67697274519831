<div class="modal__login-form form">
  <form [formGroup]="signUpForm" (ngSubmit)="signUp()">
    <div class="form__item">
      <div class="form__field">
        <i class="icon-person form__item-icon"></i>
        <input
          type="text"
          placeholder="{{ 'forms.username' | translate }}"
          formControlName="full_name"
          autocomplete="off"
        />
      </div>
      <div
        class="form__field-error"
        *ngIf="showFieldErrs(signUpForm, 'full_name')"
      >
        <span *ngIf="fieldHasErr(signUpForm, 'full_name', 'required')">
          {{ "forms.usernameRequired" | translate }}
        </span>
      </div>
    </div>

    <div class="form__item">
      <div class="form__field">
        <i class="icon-email form__item-icon"></i>
        <input
          type="email"
          placeholder="{{ 'forms.emailAddress' | translate }}"
          formControlName="email"
          autocomplete="off"
        />
      </div>
      <div class="form__field-error" *ngIf="showFieldErrs(signUpForm, 'email')">
        <span *ngIf="fieldHasErr(signUpForm, 'email', 'required')">{{
          "forms.emailRequired" | translate
        }}</span>
        <span *ngIf="fieldHasErr(signUpForm, 'email', 'email')">{{
          "forms.validEmailRequired" | translate
        }}</span>
      </div>
    </div>

    <div class="form__item">
      <div class="form__field">
        <i class="icon-key form__item-icon"></i>
        <input
          formControlName="password"
          autocomplete="off"
          placeholder="{{ 'forms.password' | translate }}"
          [type]="passwordVisible ? 'text' : 'password'"
        />
        <i
          class="form__item-icon pw"
          [ngClass]="[passwordVisible ? 'icon-eye-off' : 'icon-eye']"
          (click)="passwordVisible = !passwordVisible"
        ></i>
      </div>
      <div
        class="form__field-error"
        *ngIf="showFieldErrs(signUpForm, 'password')"
      >
        <span *ngIf="fieldHasErr(signUpForm, 'password', 'required')">{{
          "forms.passwordRequired" | translate
        }}</span>
        <span *ngIf="fieldHasErr(signUpForm, 'password', 'minlength')">{{
          "forms.passwordMinLength" | translate
        }}</span>
      </div>
    </div>

    <div class="form__item">
      <div class="form__field">
        <i class="icon-key form__item-icon"></i>
        <input
          type="password"
          formControlName="passwordConfirm"
          autocomplete="off"
          placeholder="{{ 'forms.reenterPassword' | translate }}"
          [type]="passwordVisible ? 'text' : 'password'"
        />
        <i
          class="form__item-icon pw"
          [ngClass]="[passwordVisible ? 'icon-eye-off' : 'icon-eye']"
          (click)="passwordVisible = !passwordVisible"
        ></i>
      </div>
      <div
        class="form__field-error"
        *ngIf="showFieldErrs(signUpForm, 'passwordConfirm')"
      >
        <span *ngIf="fieldHasErr(signUpForm, 'passwordConfirm', 'required')">{{
          "forms.passwordRequired" | translate
        }}</span>
        <span
          *ngIf="fieldHasErr(signUpForm, 'passwordConfirm', 'passwordMismatch')"
          >{{ "forms.passwordsMismatch" | translate }}</span
        >
        <span *ngIf="fieldHasErr(signUpForm, 'passwordConfirm', 'minlength')">{{
          "forms.passwordMinLength" | translate
        }}</span>
      </div>
    </div>

    <div class="form__item form__item-submit">
      <button type="submit" [disabled]="signUpForm.disabled">
        {{ "forms.createAccount" | translate }}
      </button>
    </div>
  </form>
</div>
