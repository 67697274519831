<div class="wrapper">
  <div class="container">
    <div class="wrapper__flex">
      <div class="content">
        <header class="section header">
          <button
            class="logout-btn bth-auto-width"
            *ngIf="isLogged()"
            (click)="logout()"
          >
            Logout
          </button>
          <div>
            <select class="lang-selector" [formControl]="langCtrl">
              <option *ngFor="let lang of availableLangs" [value]="lang">
                {{ "langNames." + lang | translate }}
              </option>
            </select>
          </div>
        </header>

        <main class="main">
          <router-outlet></router-outlet>
        </main>
      </div>
    </div>
  </div>
  <img class="wrapper__bg-1" src="/assets/images/bg-0.svg" alt="" />
  <img class="wrapper__bg-2" src="/assets/images/bg-1.svg" alt="" />
</div>

<div
  class="spinner"
  [hidden]="!displaySpinner"
  (click)="$event.stopPropagation()"
>
  <i class="icon-spin6"></i>
</div>
