import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { showFieldErrs } from 'src/app/utils/forms';
import { Observable, Subject, catchError, of, takeUntil } from 'rxjs';

@Component({
  templateUrl: 'forgot-password.component.html'
})

export class ForgotPasswordComponent implements OnInit, OnDestroy {
  showFieldErrs = showFieldErrs;
  emailControl: AbstractControl | null;
  showErrors = false;
  submitting = false;
  destroy$: Subject<void> = new Subject();

  public forgotPasswordForm: FormGroup;

  constructor(
    private readonly _router: Router,
    private readonly _appService: AppService,
    private readonly _translateService: TranslateService

  ) {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
    this.emailControl = this.forgotPasswordForm.get('email');
    this.emailControl?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.showErrors = showFieldErrs(this.forgotPasswordForm, 'email');
      });
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public sendResetLink(): void {
    this.forgotPasswordForm.markAllAsTouched();
    if (this.forgotPasswordForm.invalid) return;
    this.submitting = true;
    this._appService.recoverPassword(this.forgotPasswordForm.value.email)
      .pipe(
        catchError((error: any, caught: Observable<any>): Observable<any> => {
          const serverError = error?.error?.message;
          const control = this.forgotPasswordForm.get('email');
          control?.setErrors({ serverError });
          return of();
        })
      )
      .subscribe(data => {
        if (!this.forgotPasswordForm.get('email')?.errors?.['serverError']) {
          alert(this._translateService.instant('auth.passwordResetSent'));
          this._router.navigateByUrl('/');
        }
      })
      .add(() => {
        this.submitting = false;
      });
  }
}
