import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, of } from 'rxjs';
import { Alert } from 'src/app/components/alert/alert.interface';
import { AppService } from 'src/app/app.service';
import { fieldHasErr, scrollToFirstInvalidField, showFieldErrs } from 'src/app/utils/forms';
import { AnalyticsService } from '../../../analytics.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent {
  @Output() alertEmitter = new EventEmitter<Alert | null>();
  showFieldErrs = showFieldErrs;
  fieldHasErr = fieldHasErr;
  passwordVisible = false;
  resultText: string | null = null;

  signUpForm = new FormGroup(
    {
      full_name: new FormControl<string>('', [Validators.required]),
      email: new FormControl<string>('', [Validators.required, Validators.email]),
      password: new FormControl<string>('', [Validators.required, Validators.minLength(6)]),
      passwordConfirm: new FormControl<string>('', [Validators.required, Validators.minLength(6)])
    },
    { validators: this.checkPassword }
  );

  constructor(
    private readonly appService: AppService, private translateService: TranslateService,
    private readonly router: Router,
    private analyticsService: AnalyticsService
  ) {
    if (this.appService.email) {
      this.signUpForm.patchValue({ email: this.appService.email });
    }

    if (this.appService.profileName) {
      this.signUpForm.patchValue({ full_name: this.appService.profileName });
    }
  }

  private checkPassword(control: AbstractControl): null {
    const password: AbstractControl | null = control.get('password');
    const passwordConfirm: AbstractControl | null = control.get('passwordConfirm');

    if (!password || !passwordConfirm) return null;

    password.value === passwordConfirm.value
      ? passwordConfirm.setErrors(null)
      : passwordConfirm.setErrors({ passwordMismatch: true });

    return null;
  }

  signUp() {
    this.signUpForm.markAllAsTouched();

    if (this.signUpForm.invalid) {
      scrollToFirstInvalidField();
      return;
    }

    this.alertEmitter.emit(null);
    this.signUpForm.disable();
    const formData = this.signUpForm.getRawValue();
    this.analyticsService.sendEvent("sign_up_attempt");

    this.appService
      .signUpWithEmailPassword(formData)
      .pipe(catchError((error: any) => {
        console.error('There was an error!', error);
        this.analyticsService.sendEvent("sign_up_failure");

        this.alertEmitter.emit(this.appService.mapError(error.error.message));

        return of({ userId: null, idToken: null });
      }))
      .subscribe((data) => {
        console.log('Data response: ', data)
        this.analyticsService.sendEvent("sign_up_success");
        if (data.userId) {
          /*
          const encodedData = this.appService.encodeSignupData({
            email: formData.email as string,
            password: formData.password as string,
            appId: this.appService.appId,
          });
          */
          //this.router.navigate(['/verify', encodedData]);
          this.router.navigate(['/verify']);
        } else {
          this.resultText = this.translateService.instant('auth.accountNotCreated');
        }
      }).add(() => {
        this.signUpForm.enable();
      });
  }
}
