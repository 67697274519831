import { Component, DestroyRef } from '@angular/core';
import { Observable, Subject, catchError, filter, interval, map, of, switchMap, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Alert } from 'src/app/components/alert/alert.interface';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AnalyticsService } from '../../analytics.service';

@Component({
  templateUrl: 'verify.component.html'
})

export class VerifyComponent {
  public alert!: Alert;
  public process = {
    onProgress: false,
    onSuccess: false,
  }

  constructor(
    private readonly appService: AppService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly destroyRef: DestroyRef,
    private analyticsService: AnalyticsService
  ) {
    this.runEmailValidationCheck();
  }

  public resendEmail() {
    this.process.onProgress = true;
    this.analyticsService.sendEvent("resend_email_verification_attempt");
    this.appService
      .sendVerificationEmail()
      .pipe(catchError((error: any): Observable<any> => {
        console.log('Error: ', error)
        this.analyticsService.sendEvent("resend_email_verification_failure");
        this.alert = this.appService.mapError(error.error.message);
        return of();
      }))
      .subscribe(() => {
        this.process.onSuccess = true;
        this.analyticsService.sendEvent("resend_email_verification_success");
        this.alert = {
          type: 'success',
          message: this.translateService.instant('auth.emailSent')
        };
      }).add(() => {
        this.process.onProgress = false;
      });
  }

  private runEmailValidationCheck() {
    const isVerifiedSubj = new Subject<void>();
    interval(10000)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        takeUntil(isVerifiedSubj),
        switchMap(
          () => this.appService.checkEmailVerification().pipe(
            catchError((err) => {
              console.log('check email verification err', err);

              return of({ isEmailVerified: false });
            }),
          )
        ),
        switchMap((result) => {
          console.log({ result });

            if (result.isEmailVerified) {
              // stop rechecks interval
              isVerifiedSubj.next();
              isVerifiedSubj.complete();
              this.analyticsService.sendEvent("email_is_verified");
              this.appService.saveUserData(result);
              return of(true);
            }

          return of(false);
        }),
        filter((result) => result)
      )
      .subscribe(() => {
        this.router.navigate(['/check-requirements']);
      });
  }
}
