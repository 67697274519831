import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { delay, distinctUntilChanged, filter } from 'rxjs';
import { AppService } from './app.service';
import { TranslateService } from '@ngx-translate/core';
import { availableLangs, defaultLang } from './defs/constants';
import { FormControl } from '@angular/forms';
import { AnalyticsService } from './analytics.service';

enum Themes {
  Dark,
  Light,
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  public availableLangs = availableLangs;
  public langCtrl = new FormControl<string>(defaultLang);
  public displaySpinner = false;

  constructor(
    private readonly _appService: AppService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _translateService: TranslateService,
    private readonly _renderer: Renderer2,
    private analyticsService: AnalyticsService
  ) {
    this.langCtrl.valueChanges.subscribe((langCode) => {
      this._translateService.use(langCode as string);
    });

    /*
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.setTitle(event.url.split('?')[0]);
    });
    */

    this._route.queryParams.subscribe((params) => {
      this._appService.setUrlRedirect(params['url_redirect']);

      /**
       * @todo
       * Right now it's not being used, but an implementation with customToken
       * is expected to be done soon.
       */

      if (params['logout']) {
        this._appService.logout();
      }

      if (params['returnSecureToken']) {
        this._appService.returnSecureToken = JSON.parse(
          params['returnSecureToken']
        );
      }

      if (params['returnRefreshToken']) {
        this._appService.returnRefreshToken = JSON.parse(
          params['returnRefreshToken']
        );
      }

      if (params['appId']) {
        this._appService.appId = params['appId'];
        this.analyticsService.setAppId(params['appId']);
      }

      if (params['idToken']) {
        this._appService.checkIdToken(params['idToken']);
      }

      if (params['email']) {
        this._appService.email = params['email'];
      }

      if (params['profile']) {
        this._appService.profileName = params['profile'];
      }

      if (params['view']) {
        this._appService.initialLoginTab = params['view'];
      }

      if (params['lang']) {
        const lang = availableLangs.includes(params['lang'])
          ? params['lang']
          : defaultLang;
        this.langCtrl.patchValue(lang);
      }

      if (params['theme'] === 'dark') {
        this.applyTheme(Themes.Dark);
      } else {
        this.applyTheme(Themes.Light);
      }

      if (params['forceLogout']) {
        this._appService.forceLogout = params['forceLogout'];
      }
    });

    if (this._appService.displaySpinner$) {
      this._appService.displaySpinner$
        .pipe(delay(200), distinctUntilChanged())
        .subscribe((val) => {
          this.displaySpinner = val;
        });
    }
  }

  private applyTheme(theme: Themes) {
    if (theme === Themes.Light) {
      this._renderer.removeClass(document.body, 'theme-dark');
      this._renderer.addClass(document.body, 'theme-light');
    } else {
      this._renderer.removeClass(document.body, 'theme-light');
      this._renderer.addClass(document.body, 'theme-dark');
    }
  }

  ngAfterViewInit() {
    const loader = document.getElementById('loader');
    if (loader) {
      this._renderer.setStyle(loader, 'display', 'none');
    } else {
      console.error('Loader element not found.');
    }
  }

  /*
  public setTitle(url: '/'|'/signup'|'/forgot-password'|'/verify') {
    const nUrl = url === '/' ? '/signin' : url;
    const signIntoAccount = 'auth.signIntoAccount';
    const createAccount = 'forms.createAccount';
    const recoverPassword = 'auth.recoverPassword';
    const resendVerification = 'auth.resendVerification';

    this._translateService.get([
      signIntoAccount,
      createAccount,
      recoverPassword,
      resendVerification,
    ]).subscribe((translations) => {
      const titleMap = {
        '/signin': {
          title: translations[signIntoAccount],
          type: 'signin',
        },
        '/signup': {
          title: translations[createAccount],
          type: 'signup',
        },
        '/forgot-password': {
          title: translations[recoverPassword],
          type: 'forgot-password',
        },
        '/verify': {
          title: translations[resendVerification],
          type: 'verify-account',
        },
      }

      let obj = titleMap[nUrl]
      obj = obj ?? obj['/signin'];

      this.title = obj.title;
      this.type = obj.type;
    });
  }
  */
  logout() {
    this.analyticsService.sendEvent('logout_attempt');
    this._appService.logout();
    this._router.navigate(['/']);
  }

  isLogged() {
    return this._appService.isLogged();
  }
}
