<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->

<div class="modal">
  <div class="modal__login">
    <div class="modal__login-switcher-container">
      <div class="modal__login-switcher">
        <div class="modal__login-logo">
          <img
            class="modal__logo"
            src="/assets/images/logo.png"
            alt="PLAY logo"
            width="150"
          />
        </div>
        <span
          [ngClass]="{ active: activeTab === Tabs.Login }"
          (click)="switchToTab(Tabs.Login)"
        >
          {{ "forms.signIn" | translate }}
        </span>
        <span
          [ngClass]="{ active: activeTab === Tabs.Register }"
          (click)="switchToTab(Tabs.Register)"
        >
          {{ "forms.createAccount" | translate }}
        </span>
      </div>
    </div>

    <div *ngIf="activeTab === Tabs.Login" class="modal__login-form-column">
      <app-login (alertEmitter)="handleAlert($event)"></app-login>
    </div>
    <div *ngIf="activeTab === Tabs.Register" class="modal__login-form-column">
      <app-register (alertEmitter)="handleAlert($event)"></app-register>
    </div>
  </div>
</div>

<app-alert
  class="auth-alert"
  *ngIf="alert"
  [type]="alert.type"
  [message]="alert.message"
></app-alert>
