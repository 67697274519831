import { Injectable } from '@angular/core';
import { environment } from '../environment/environment';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  public appId!: string;

  setUserId(userId: string) {
    gtag('set', 'user_id', userId);
    localStorage.setItem('user_id', userId);
  }

  setAppId(appId: string) {
    this.appId = appId;
    gtag('config', environment.gtag_measurement_id, {
      'app_id': appId
    });


  }

  clearUserId() {
    gtag('set', 'user_id', null);
    localStorage.removeItem('user_id');
  }

  sendEvent(eventName: string, eventParams: any = {}) {
    /*
    if (this.appId) {
      eventParams['app_id'] = this.appId;
    }*/
    gtag('event', eventName, eventParams);
  }
}
