import { Route } from "@angular/router";
import { ForgotPasswordComponent } from "./view/forgot-password/forgot-password.component";
import { AuthComponent } from "./view/auth/auth.component";
import { RegisterComponent } from './view/auth/register/register.component';
import { LoginComponent } from './view/auth/login/login.component';
import { VerifyComponent } from "./view/verify/verify.component";
import { CheckRequirementsComponent } from './view/check-requirements/check-requirements.component';
import { inject } from "@angular/core";
import { AppService } from "./app.service";
import { WalletCreationComponent } from "./view/wallet-creation/wallet-creation.component";

export const appRoutes: Route[] = [
  {
    path: '',
    component: AuthComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    //path: 'verify/:data',
    path: 'verify',
    component: VerifyComponent,
  },
  {
    path: 'check-requirements',
    component: CheckRequirementsComponent,
    canMatch: [() => inject(AppService).isLogged()]
  },
  {
    path: 'wallet-creation',
    component: WalletCreationComponent,
    canMatch: [() => inject(AppService).isLogged()]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

export const appComponents = [
  AuthComponent,
  LoginComponent,
  RegisterComponent,
  ForgotPasswordComponent,
  VerifyComponent,
  CheckRequirementsComponent,
];
