import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DialogModule } from '@angular/cdk/dialog';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { appComponents, appRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { AlertComponent } from './components/alert/alert.component';
import { defaultLang } from './defs/constants';
import { authInterceptor } from './core/api.interceptor';
import { AppService } from './app.service';
import { WalletCreationComponent } from './view/wallet-creation/wallet-creation.component';

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

function initFactory(appService: AppService) {
  return () => appService.init();
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  /**
   * Method for handling missing translations.
   * @param {MissingTranslationHandlerParams} params.
   * return string.
   */
  handle(params: MissingTranslationHandlerParams) {
    return '';
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    ...appComponents,
    WalletCreationComponent,
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule,
    HttpClientModule,
    FormsModule,
    DialogModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: defaultLang,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    AppService,
    {
      provide: APP_INITIALIZER,
      useFactory: initFactory,
      deps: [AppService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
