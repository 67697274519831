import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { fieldHasErr, showFieldErrs, showFormErrs } from 'src/app/utils/forms';

@Component({
  selector: 'app-wallet-creation',
  templateUrl: './wallet-creation.component.html'
})
export class WalletCreationComponent {
  private readonly appService = inject(AppService);
  passwordVisible = false;
  showFieldErrs = showFieldErrs;
  fieldHasErr = fieldHasErr;
  showFormErrs = showFormErrs;

  constructor(private router: Router) { }

  form = new FormGroup(
    {
      password: new FormControl<string>('', [Validators.required]),
      passwordConfirm: new FormControl<string>(''),
    },
    (input) => {
      const form = input as FormGroup;
      const pwd = form.get('password')?.value || '';
      const pwdConfirm = form.get('passwordConfirm')?.value || '';

      if (!pwd.length || pwd === pwdConfirm) return null;
      return { passwordsMismatch: true };
    }
  );

  createWallet() {
    this.form.markAllAsTouched();

    if (!this.form.valid || this.form.disabled) {
      return;
    }

    const password = this.form.value.password || null;

    if (password !== null) {
      this.form.disable();

      this.appService.createWallets(password)
        .pipe(catchError((error: any): Observable<any> => {
          this.form.enable();

          console.log('Error on getProjectInformation: ', error);
          //this.alert = this.appService.mapError(error.error.message);
          return of();
        }))
        .subscribe((data) => {
          console.log(data);
          this.router.navigate(['/check-requirements']);
        });
    }
  }
}
