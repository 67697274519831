<div class="modal__login-form form">
  <form [formGroup]="signInForm" (ngSubmit)="signIn()">
    <div class="form__item">
      <div class="form__field">
        <i class="icon-email form__item-icon"></i>
        <input
          type="email"
          placeholder="{{ 'forms.emailAddress' | translate }}"
          formControlName="email"
          autocomplete="username"
        />
      </div>
      <div class="form__field-error" *ngIf="showFieldErrs(signInForm, 'email')">
        <span *ngIf="signInForm.controls['email'].hasError('required')">
          {{ "forms.emailRequired" | translate }}
        </span>
        <span *ngIf="signInForm.controls['email'].hasError('email')">
          {{ "forms.validEmailRequired" | translate }}
        </span>
      </div>
    </div>

    <div class="form__item">
      <div class="form__field">
        <i class="icon-key form__item-icon"></i>
        <input
          type="password"
          formControlName="password"
          autocomplete="current-password"
          placeholder="{{ 'forms.password' | translate }}"
          [type]="passwordVisible ? 'text' : 'password'"
        />
        <i
          class="form__item-icon pw"
          [ngClass]="[passwordVisible ? 'icon-eye-off' : 'icon-eye']"
          (click)="passwordVisible = !passwordVisible"
        ></i>
      </div>
      <div
        class="form__field-error"
        *ngIf="showFieldErrs(signInForm, 'password')"
      >
        {{ "forms.passwordRequired" | translate }}
      </div>
    </div>

    <div class="form__item form__item-controls">
      <label class="custom-checkbox">
        <input type="checkbox" formControlName="rememberMe" />
        {{ "forms.rememberMe" | translate }}
      </label>

      <a class="forgot-password" routerLink="forgot-password">
        {{ "forms.forgotPassword" | translate }}
      </a>
    </div>

    <div class="form__item form__item-submit">
      <button type="submit" [disabled]="signInForm.disabled">
        {{ "forms.signIn" | translate }}
        <i class="icon-arrow-right"></i>
      </button>
    </div>
  </form>
</div>
